<script>
import Layout from "../../layouts/main";
import { mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import { api } from "@/state/services";
import { VclList } from "vue-content-loading";

export default {
  locales: {
    pt: {
      'New Address': 'Nova carteira',
      'Date': 'Data',
      'Address': 'Endereço',
      'Address is required.': 'Endereço é obrigatório.',
      'Send': 'Cadastrar',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INACTIVO',
      'CONFIRMING': 'AGUARDANDO CONFIRMAÇÃO',
      'We apologize, we are unable to retrieve information at this time. Please try again later.' : 'Pedimos desculpas, não podemos recuperar informações neste momento. Por favor, tente novamente mais tarde.',
      'We sent an email to confirm your new bitcoin address.': 'Enviamos um e-mail para confirmar seu novo endereço de bitcoin.'
    },
    es: {
      'New Address': 'Nueva billetera',
      'Date': 'Data',
      'Address': 'Habla a',
      'Address is required.': 'Address is required',
      'Send': 'Registrar',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INACTIVO',
      'CONFIRMING': 'AGUARDANDO CONFIRMAÇÃO',
      'We apologize, we are unable to retrieve information at this time. Please try again later.' : 'Lo sentimos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.',
      'We sent an email to confirm your new bitcoin address.': 'Enviamos un correo electrónico para confirmar su nueva dirección de bitcoin.'
    }
  },
  components: {
    Layout,
    VclList
  },
  computed: {
    ...mapState({
      account: state => state.account
    })
  },
  data() {
    return {
      table: {
        bitcoin: {
          heade: [
            'Date', 'Address', 'Status'
          ],
          body: null,
          loading: true,
          errored: false,
          empty: false,
        }
      },

      alert: {
        bitcoin: { type: '', message: '' },
        bitcoinModal: { type: '', message: '' }
      },

      bitcoin: { modal: false, default: null, address: "" },
    };
  },
  validations: {
    bitcoin: {
      address: { required }
    },
  },
  methods: {
    getBitcoin() {
      this.table.bitcoin.body = null

      this.table.bitcoin.loading = true
      this.table.bitcoin.errored = false
      this.table.bitcoin.empty = false

      api
        .get('wallet/bitcoin')
        .then(response => {
          if (response.data.status=='success') {
            this.bitcoin.default = response.data.default
            this.table.bitcoin.body = response.data.list
          } else {
            this.table.bitcoin.body = null
          }
        })
        .catch(error => {
          this.table.bitcoin.errored = error
        })
        .finally(() => {
          this.table.bitcoin.loading = false
          if (this.table.bitcoin.body=='' || this.table.bitcoin.body==null) {
            this.table.bitcoin.empty = true
          }
        })
    },
    bitcoinSubmit() {
      this.$v.bitcoin.$touch();

      if (this.bitcoin.address) {
        api
          .post('wallet/bitcoin', {
            address: this.bitcoin.address,
          })
          .then(response => {
            if (response.data.status=='success') {
              this.bitcoin.address = ''
              this.$v.bitcoin.$reset()

              this.alert.bitcoin.type = 'alert-success'
              this.alert.bitcoin.message = response.data.message

              this.bitcoin.modal = false
              this.bitcoin.loading = true
              this.getBitcoin()
            } else {
              this.alert.bitcoinModal.type = 'alert-danger'
              this.alert.bitcoinModal.message = response.data.message
            }
          })
      }
    },
  },
  mounted() {
    this.getBitcoin()
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t('Bitcoin') }}</h4>
          <div v-if="!table.bitcoin.empty" class="page-title-right">
            <button
              type="button"
              class="btn btn-dark btn-sm text-uppercase"
              @click="bitcoin.modal = true"
            >{{ t('New Address') }}</button>
          </div>
        </div>
        <div v-if="alert.bitcoin.message" :class="'alert ' + alert.bitcoin.type">{{ t(alert.bitcoin.message) }}</div>
        <div class="card">
          <div class="card-body">
            <div v-if="bitcoin.default" class="pt-3 pb-0 pl-3 p-r">
              <h4 class="card-title mb-0">{{ t('Address') }}</h4>
              <p class="card-title-desc">{{ this.bitcoin.default }}</p>
            </div>
            <div v-if="table.bitcoin.errored">{{ t('We apologize, we are unable to retrieve information at this time. Please try again later.') }}</div>
            <div v-else-if="table.bitcoin.empty">
              <b-button v-on:click="bitcoin.modal = true;" variant="outline-dark" class="btn btn-block p-5"><i class="bx bx-plus-circle font-size-24"></i><br> {{ t('New Address') }} </b-button>

            </div>
            <div v-else class="table-responsive">
              <vcl-list v-if="table.bitcoin.loading" class="col-lg-6"></vcl-list>
              <table v-else class="table table-nowrap">
                <thead class="thead-light">
                  <tr>
                    <th v-for="th in table.bitcoin.heade" :key="th">{{ t(th) }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(td,index) in table.bitcoin.body" :key="index">
                    <td v-for="(data,index) in td" :key="index">
                      <template v-if="index == 'status'">
                        <span v-if="data == 'ACTIVE'" class="badge badge-pill badge-success">{{ t(data) }}</span>
                        <span v-else-if="data == 'INACTIVE'" class="badge badge-pill badge-warning">{{ t(data) }}</span>
                        <span v-else class="badge badge-pill badge-info">{{ t(data) }}</span>
                      </template>
                      <template v-else>
                        {{ t(data) }}
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="bitcoin.modal" :title="t('New Address')" centered>
      <b-form @submit.prevent="bitcoinSubmit">
        <div v-if="alert.bitcoinModal.message" :class="'alert ' + alert.bitcoinModal.type">{{ t(alert.bitcoinModal.message) }}</div>
        <b-form-group id="bitcoin-address" :label="t('Address')" label-for="bitcoin-address">
          <b-form-input id="bitcoin-address" v-model="bitcoin.address" type="text" :class="{ 'is-invalid': $v.bitcoin.address.$error }" autocomplete="off"></b-form-input>
          <div v-if="$v.bitcoin.address.$error" class="invalid-feedback">
            <span v-if="!$v.bitcoin.address.required">{{ t('Address is required.') }}</span>
          </div>
        </b-form-group>
        <b-button :disabled="!this.bitcoin.address" type="submit" variant="default">{{ t('Send') }}</b-button>
      </b-form>
      <template v-slot:modal-footer>
        <div></div>
      </template>
    </b-modal>
  </Layout>
</template>